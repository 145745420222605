@content13: content13;
.@{content13}-wrapper {
  min-height: 380px;
  background: url("/src/images/works3D/digital-landscape-home.jpg") no-repeat bottom;
  // background-size: cover;
  background-size: 100%;
  // margin: 0 auto;
  overflow: hidden;
  padding: 200px 0;
  &.home-page-wrapper {
    .title-wrapper {
      margin-bottom: 32px;
    }
  }
  .title-content {
    line-height: 32px;
  }
}

@media screen and (max-width: 767px) {
  .@{content13}-wrapper {
    padding-bottom: 0;
  }
}
