body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.services-home-wrapper p {
  padding: 0;
  margin: 0;
}
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: #000000;
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.banner5 {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #fdfdfd;
  overflow: hidden;
}
.banner5 .banner5-page {
  padding: 1px 0 0;
}
.banner5-title {
  font-size: 48px;
  line-height: 1.5;
  color: #5f9bf1;
  margin-bottom: 8px;
}
.banner5-title-wrapper {
  width: 40%;
  max-width: 450px;
  position: relative;
  top: 30%;
  left: 24px;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.banner5-explain {
  color: #333;
  font-size: 24px;
  margin-bottom: 24px;
}
.banner5-content {
  font-size: 18px;
  color: #666;
  margin-bottom: 48px;
}
.banner5-button-wrapper .banner5-button {
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
  box-shadow: 0 8px 24px rgba(95, 155, 241, 0.35);
  border-radius: 20px;
  border: none;
  padding: 0 42px;
  line-height: 40px;
  height: 40px;
  transition: transform 0.3s, box-shadow 0.3s;
}
.banner5-button-wrapper .banner5-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 13px 24px rgba(95, 155, 241, 0.45);
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
}
.banner5-image {
  overflow: hidden;
  width: 55%;
  max-width: 710px;
  position: absolute;
  top: 15%;
  right: 24px;
}
@media screen and (max-width: 767px) {
  .banner5 {
    min-height: 500px;
  }
  .banner5 .banner5-page.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .banner5-title-wrapper {
    text-align: center;
    position: static;
    width: 100%;
    margin-top: 64px;
    left: 0;
    top: 0;
  }
  .banner5-title {
    font-size: 32px;
  }
  .banner5-explain {
    font-size: 18px;
  }
  .banner5-content {
    font-size: 14px;
  }
  .banner5-image {
    position: static;
    width: 100%;
    top: 0;
    right: 0;
    margin: 32px 0 24px;
  }
}
.content5-wrapper {
  background-color: #fafafa;
  min-height: 720px;
}
.content5-wrapper .content5 > p {
  text-align: center;
}
.content5-wrapper .content5-img-wrapper {
  margin: 0 auto;
  left: 0;
  right: 0;
}
.content5-wrapper .content5-img-wrapper .block {
  margin-bottom: 24px;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content {
  display: block;
  background: #fff;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #e9e9e9;
  transform: translateY(0);
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  border: none;
  color: #000000;
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover {
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content > span {
  width: 100%;
  height: 160px;
  display: block;
  background: #e9e9e9;
  padding: 5%;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content p {
  width: 100%;
  line-height: 30px;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover p {
  bottom: 0;
}
@media screen and (max-width: 767px) {
  .content5-wrapper {
    height: 2000px;
    overflow: hidden;
  }
  .content5-wrapper .content5 ul li {
    display: block;
    width: 100%;
    padding: 2%;
  }
  .content5-wrapper .content5 ul li span {
    height: 168px;
  }
  .content5-wrapper .content5 ul li p {
    position: relative;
    bottom: 0;
  }
}
