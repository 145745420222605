body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: #000000;
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.banner3 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner3 .banner3-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  color: #fff;
  max-width: 845px;
  height: 500px;
  width: 80%;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}
.banner3 .banner3-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner3 .banner3-slogan {
  font-size: 68px;
  line-height: 80px;
  text-indent: 2px;
  font-weight: 600;
  margin: 26px auto 38px;
  overflow: hidden;
}
.banner3 .banner3-name-en {
  display: block;
  font-size: 14px;
  line-height: 32px;
  font-weight: 400;
}
.banner3 .banner3-name {
  font-size: 24px;
  overflow: hidden;
  opacity: 0.8;
}
.banner3 .banner3-button {
  display: block;
  margin: 72px auto 0;
  background: #034365;
  background: -moz-linear-gradient(left, #034365 0%, #001b33 100%);
  background: linear-gradient(to right, #034365 0%, #001b33 100%);
  box-shadow: 0 8px 16px #0a52ab;
  border: none;
  transition: background 0.45s ease-out;
  width: 132px;
  line-height: 42px;
  height: 42px;
  border-radius: 42px;
}
.banner3 .banner3-time {
  font-size: 14px;
  line-height: 24px;
  margin-top: 24px;
}
@media screen and (max-width: 767px) {
  .banner3 {
    background-attachment: inherit;
  }
  .banner3 .banner3-text-wrapper {
    width: 90%;
    height: 50%;
  }
  .banner3 .banner3-name-en {
    font-size: 12px;
  }
  .banner3 .banner3-slogan {
    font-size: 24px;
    line-height: 1.5;
    margin: 12px 0;
  }
  .banner3 .banner3-name {
    font-size: 14px;
  }
}
.content1-wrapper {
  height: 360px;
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0 24px;
}
.content1-wrapper .content1-img {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1-wrapper .content1-img span {
  display: block;
  width: 250px;
}
.content1-wrapper .content1-img span img {
  display: block;
}
.content1-wrapper .content1-text {
  padding: 0 32px;
  height: 100%;
}
.content1-wrapper .content1-text .content1-content,
.content1-wrapper .content1-text .content1-title {
  position: relative !important;
}
.content1-wrapper .content1-text .content1-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content1-wrapper .content1-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: 600px;
  }
  .content1-wrapper .content1-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content1-wrapper .content1-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content1-wrapper .content1-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content1-wrapper .content1-text .content1-content,
  .content1-wrapper .content1-text .content1-title {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text .content1-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.content2-wrapper {
  height: 360px;
}
.content2-wrapper .content2 {
  height: 100%;
  padding: 0 24px;
}
.content2-wrapper .content2-img {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content2-wrapper .content2-img span {
  display: block;
  width: 250px;
}
.content2-wrapper .content2-img span img {
  display: block;
}
.content2-wrapper .content2-text {
  padding: 0 32px;
  height: 100%;
}
.content2-wrapper .content2-text .content2-content,
.content2-wrapper .content2-text .content2-title {
  position: relative !important;
}
.content2-wrapper .content2-text .content2-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content2-wrapper .content2-text .content2-content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content2-wrapper {
    height: 600px;
  }
  .content2-wrapper .content2-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content2-wrapper .content2-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content2-wrapper .content2-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content2-wrapper .content2-text .content2-content,
  .content2-wrapper .content2-text .content2-title {
    width: 100%;
    top: auto;
  }
  .content2-wrapper .content2-text .content2-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.content9-wrapper {
  padding-bottom: 64px;
}
.content9-wrapper.home-page-wrapper {
  overflow: initial;
}
.content9-wrapper .content9 {
  min-height: 800px;
  padding: 64px 0 0;
}
.content9-wrapper .timeline {
  position: relative;
}
.content9-wrapper .timeline:before {
  display: block;
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  width: 0;
  height: calc(100% - 108px);
  margin: 34px 0;
  border-left: 2px #ebedf0 dashed;
}
.content9-wrapper .block-wrapper {
  color: #000000;
  display: flex;
  position: relative;
  margin-bottom: 70px;
  min-height: 160px;
}
.content9-wrapper .block-wrapper:last-child {
  margin-bottom: 0;
}
.content9-wrapper .image-wrapper,
.content9-wrapper .text-wrapper {
  width: 50%;
}
.content9-wrapper .image-wrapper {
  text-align: center;
}
.content9-wrapper .image-wrapper .block-img,
.content9-wrapper .image-wrapper .name-wrapper {
  float: right;
  clear: both;
  width: 262px;
  margin: auto;
}
.content9-wrapper .image-wrapper .block-img {
  height: 98px;
}
.content9-wrapper .image-wrapper .block-img img {
  height: 100%;
}
.content9-wrapper .image-wrapper .name-wrapper {
  margin: 16px 0 0;
}
.content9-wrapper .image-wrapper .name-wrapper .block-name {
  font-size: 14px;
  margin-bottom: 4px;
}
.content9-wrapper .image-wrapper .name-wrapper .block-post {
  font-size: 12px;
  color: #697b8c;
}
.content9-wrapper .text-wrapper {
  padding-left: 40px;
}
.content9-wrapper .text-wrapper .block-time {
  font-size: 12px;
  color: #697b8c;
  line-height: 18px;
  min-height: 18px;
}
.content9-wrapper .text-wrapper .block-title {
  margin: 8px 0 12px;
  font-size: 14px;
  position: relative;
  min-height: 18px;
}
.content9-wrapper .text-wrapper .block-icon {
  position: absolute;
  left: -40px;
  transform: translateX(calc(-50% + 1px));
}
.content9-wrapper .text-wrapper .block-content {
  width: 300px;
  color: #314659;
  font-size: 12px;
  min-height: 18px;
}
@media screen and (max-width: 767px) {
  .content9-wrapper {
    padding-bottom: 0;
  }
  .content9-wrapper .content9 {
    padding: 64px 24px;
  }
  .content9-wrapper .timeline:before {
    left: 0;
    height: 100%;
    margin: 0;
  }
  .content9-wrapper .block-wrapper {
    display: block;
    min-height: 100px;
    margin-bottom: 54px;
  }
  .content9-wrapper .image-wrapper,
  .content9-wrapper .text-wrapper {
    width: 100%;
  }
  .content9-wrapper .image-wrapper .block-img {
    display: none;
  }
  .content9-wrapper .image-wrapper .name-wrapper {
    text-align: left;
    float: left;
    width: 100%;
    padding-left: 40px;
    margin: auto;
  }
  .content9-wrapper .image-wrapper .block-name,
  .content9-wrapper .image-wrapper .block-post {
    display: inline-block;
  }
  .content9-wrapper .image-wrapper .block-name {
    margin-right: 8px;
  }
  .content9-wrapper .text-wrapper .block-content {
    display: none;
  }
  .content9-wrapper .text-wrapper .block-title {
    font-size: 16px;
  }
}
.content12-wrapper {
  background-color: #fafafa;
  min-height: 470px;
}
.content12-wrapper .content12 {
  padding: 64px 24px;
}
.content12-wrapper .content12 > p {
  text-align: center;
}
.content12-wrapper .img-wrapper {
  margin: 0 auto;
  left: 0;
  right: 0;
}
.content12-wrapper .img-wrapper .block {
  margin-bottom: 40px;
}
.content12-wrapper .img-wrapper .block .block-content {
  display: flex;
  border-radius: 4px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border: none;
  height: 64px;
  align-items: center;
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out;
}
.content12-wrapper .img-wrapper .block .block-content > span {
  width: 100%;
  display: block;
}
@media screen and (max-width: 767px) {
  .content12-wrapper {
    overflow: hidden;
  }
  .content12-wrapper .content12 ul li {
    display: block;
    width: 100%;
    padding: 2%;
  }
  .content12-wrapper .content12 ul li span {
    height: 168px;
  }
}
div > .templates-wrapper > .jrhtw9ph4a-editor_css {
  position: absolute;
  background-color: rgba(0, 21, 41, 0);
  box-shadow: none;
}
.home-page > .header2-menu > .active {
  color: #ffffff;
}
.home-page > .header2-menu > .menu-item:hover {
  color: #e0e7ff;
  border-bottom-color: #ffffff;
}
#Banner3_0.lihrajp3ekm-editor_css {
  height: 50vh;
}
