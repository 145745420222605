@content4: content4;
@import url('https://fonts.googleapis.com/css2?family=Baloo+2&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

.@{content4}-wrapper {
  min-height: 720px;
  background: #fafafa;
  .@{content4} {
    height: 100%;
    overflow: hidden;
    &-video {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      overflow: hidden;
      max-width: 800px;
      margin: auto;
      background: #fff;
      box-shadow: 0 4px 8px rgba(0, 0, 0, .15);
      video {
        display: block;
        margin: auto;
      }
    }
    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      // margin-top: -200px;
      .text-wrapper {
        flex: 1;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        // margin-top: -200px;
        .description {
          text-align: left;
          font-family: 'Baloo 2', cursive; /* or 'Poppins', sans-serif; */
        }
        .buttons-wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: top;
          align-items: center;

        }
      }
      .model-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        // padding-left: 20px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{content4}-wrapper {
    min-height: 350px;
    .@{content4} {
      overflow: hidden;
      width: 90%;
      margin: auto;
      .text-wrapper {
        justify-content: center;
        align-items: center;
        margin-top: 0;
        .description {
          text-align: center;
        }
        .buttons-wrapper {
          justify-content: center;
        }
      }
      .model-wrapper {
        margin-top: 20px;
        width: 100%;
      }
    }
  }
}
