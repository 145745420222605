body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 90%;
  position: relative;
  margin: 0% 10%;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: #000000;
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.content13-wrapper {
  min-height: 380px;
  background: url("/src/images/works3D/digital-landscape-home.jpg") no-repeat bottom;
  background-size: 100%;
  overflow: hidden;
  padding: 200px 0;
}
.content13-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content13-wrapper .title-content {
  line-height: 32px;
}
@media screen and (max-width: 767px) {
  .content13-wrapper {
    padding-bottom: 0;
  }
}
@font-face {
  font-family: 'Baloo 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/baloo2/v16/wXK0E3kTposypRydzVT08TS3JnAmtdgazapv.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1s.ttf) format('truetype');
}
.content4-wrapper {
  min-height: 720px;
  background: #fafafa;
}
.content4-wrapper .content4 {
  height: 100%;
  overflow: hidden;
}
.content4-wrapper .content4-video {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  max-width: 800px;
  margin: auto;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
.content4-wrapper .content4-video video {
  display: block;
  margin: auto;
}
.content4-wrapper .content4-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
.content4-wrapper .content4-wrapper .text-wrapper {
  flex: 1;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.content4-wrapper .content4-wrapper .text-wrapper .description {
  text-align: left;
  font-family: 'Baloo 2', cursive;
  /* or 'Poppins', sans-serif; */
}
.content4-wrapper .content4-wrapper .text-wrapper .buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: top;
  align-items: center;
}
.content4-wrapper .content4-wrapper .model-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .content4-wrapper {
    min-height: 350px;
  }
  .content4-wrapper .content4 {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .content4-wrapper .content4 .text-wrapper {
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }
  .content4-wrapper .content4 .text-wrapper .description {
    text-align: center;
  }
  .content4-wrapper .content4 .text-wrapper .buttons-wrapper {
    justify-content: center;
  }
  .content4-wrapper .content4 .model-wrapper {
    margin-top: 20px;
    width: 100%;
  }
}
#Content13_0.ligmuqnq4zq-editor_css {
  min-height: 100px;
}
